import React, { Component } from 'react';
import { IonGrid, IonRow, IonCol, IonButton } from '@ionic/react';
import { SmallText, Title, Spacer } from '../common';
import { makeKey, isDefined, isWebConfig, forwardTo } from '../../lib/utils';
import { withTranslation } from '../../lib/translate';
import Basket from '../../lib/basket';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { removeVoucher, setModal } from '../../store/actions';
import Modal from '../../components/modal';

import './index.css';

class OrderContent extends Component {
  drawSubItems = (basketItem = {} /*, basketItemIndex*/) => {
    const { selectedChoices, quantity } = basketItem;
    const basketInstance = this.props.basketInstance || Basket;
    const { profile } = this.props;

    if (selectedChoices && selectedChoices.length > 0) {
      return selectedChoices.map((choiceGroup, choiceGroupIndex) => {
        return (choiceGroup || []).map((choice, choiceIndex) => {
          const { sku } = choice;
          return (
            <IonRow
              key={makeKey(
                choiceGroupIndex,
                choiceIndex,
                sku,
              )} /*onClick={ () => this.handleOrderItemClick(basketItem, basketItemIndex) }*/
            >
              <IonCol size="50px"></IonCol>
              <IonCol className="paddLR grow small-text">
                {quantity} x {Basket.getProductName(choice, profile)}
              </IonCol>
              <IonCol className="righted paddLR small-text">
                {basketInstance.calculateSubItemPrice(choice, quantity) !== 0
                  ? basketInstance._calculateSubItemPrice(choice, quantity)
                  : null}
              </IonCol>
            </IonRow>
          );
        });
      });
    }
    return null;
  };

  render() {
    const {
      handleOrderItemClick,
      __,
      history,
      profile,
      showAddItems,
      isRemoveVoucherModalOpen,
      dispatch,
    } = this.props;
    const basketInstance = this.props.basketInstance || Basket;
    const type = this.props.type || 'order';
    const appliedPoints = basketInstance.getAppliedPoints();
    const deliveryPrice = basketInstance.getDeliveryPrice();
    const deliveryPriceUnformated = basketInstance._getDeliveryPrice();
    const appliedVoucher = basketInstance.getAppliedVocuher();
    const total = basketInstance._getTotal(true);
    let location =
      history && history.location && history.location.pathname === '/history-details'
        ? true
        : false;

    return (
      <IonGrid className="order-content-wrapper paddLR">
        {basketInstance.getItems().map((basketItem, basketItemIndex) => {
          const { quantity, item, instructions } = basketItem;
          return (
            <div key={basketItemIndex} className="basket-item-wrapper">
              <IonRow
                key={makeKey(basketItemIndex, item.sku)}
                onClick={() => {
                  if (handleOrderItemClick) {
                    handleOrderItemClick(basketItem, basketItemIndex);
                  }
                }}
              >
                <IonCol className="paddLR grow small-text">
                  {quantity} x {Basket.getProductName(item, profile)}
                </IonCol>
                <IonCol className="righted paddLR self-center small-text">
                  {basketInstance.calculateItemPriceByIndex(basketItemIndex, false) > 0
                    ? basketInstance._calculateItemPriceByIndex(basketItemIndex, false)
                    : null}
                </IonCol>
              </IonRow>
              {this.drawSubItems(basketItem, basketItemIndex)}
              {instructions && instructions !== '' ? (
                <IonRow>
                  <IonCol>
                    <SmallText>{__('Special Notes')}:</SmallText>
                  </IonCol>
                  <IonCol className="righted instruction-grow">
                    <SmallText>{instructions}</SmallText>
                  </IonCol>
                </IonRow>
              ) : null}
            </div>
          );
        })}
        {showAddItems ? (
          <IonRow className="righted add-items-row">
            <IonButton
              size="small"
              color="black"
              className="add-items-btn"
              onClick={() => forwardTo('/order')}
            >
              + {__('Add Items')}
            </IonButton>
          </IonRow>
        ) : null}
        {appliedVoucher &&
          appliedVoucher.length > 0 &&
          appliedVoucher.map((voucher) => {
            return (
              <div key={voucher.id}>
                <Spacer size={1} />
                <Title className="centered">{__('Vouchers')}</Title>
                <IonRow
                  onClick={() =>
                    location ? null : dispatch(setModal('isRemoveVoucherModalOpen', true))
                  }
                >
                  <IonCol className="paddLR grow">{__(voucher.reward.name)} </IonCol>
                  <IonCol className="righted paddLR">
                    {basketInstance._calculateAppliedVocuhersPrice(true, null)}
                  </IonCol>
                </IonRow>
                <IonRow className="subtotal-bordered-bottom">
                  <IonCol className="paddLR grow">{__('Subtotal')}</IonCol>
                  <IonCol className="righted paddLR">{basketInstance._getSubTotal()}</IonCol>
                </IonRow>
                <Modal
                  className="remove-apply-voucuher"
                  title={__('Do you want to remove voucher') + ' ?'}
                  actionLabel={__('Yes')}
                  action={() => dispatch(removeVoucher(voucher.id))}
                  isOpen={!!isRemoveVoucherModalOpen}
                  onDidDismiss={() => dispatch(setModal('isRemoveVoucherModalOpen', false))}
                  noContent={true}
                />
              </div>
            );
          })}
        <>
          {isDefined(appliedPoints) && appliedPoints > 0 ? (
            <IonRow
              onClick={() => {
                if (type === 'order' && history) {
                  if (isWebConfig()) {
                    this.props.handleApplyModal(true);
                  } else {
                    history.push('/apply-points');
                  }
                }
              }}
            >
              <IonCol className="paddLR grow">
                {appliedPoints}&nbsp;{__('Points Redeem')}
              </IonCol>
              <IonCol className="righted paddLR">
                {basketInstance._calculatePointsAppliedPrice(null, true)}
              </IonCol>
            </IonRow>
          ) : null}
          {deliveryPriceUnformated > 0 ? (
            <IonRow className="total-bordered-bottom">
              <IonCol className="paddLR grow">{__('Delivery Charge')}</IonCol>
              <IonCol className="righted paddLR">
                {/* basketInstance._calculatePointsAppliedPrice(null, true) */} {deliveryPrice}
              </IonCol>
            </IonRow>
          ) : null}
          {basketInstance.getServiceCharge() > 0 ? (
            <div key="service_charge" className="basket-item-wrapper">
              <IonRow
                className="basket-item-service-charge-row"
                onClick={() => {
                  if (handleOrderItemClick) {
                    basketInstance.setServicePercentage(0);
                  }
                }}
              >
                <IonCol className="paddLR grow small-text">
                  {__('Service charge')}&nbsp;{basketInstance._getServicePercentage()}
                </IonCol>
                <IonCol className="righted paddLR self-center small-text">
                  {basketInstance._getServiceCharge(true)}
                </IonCol>
              </IonRow>
            </div>
          ) : null}
        </>
        <IonRow
          className={
            deliveryPriceUnformated > 0
              ? 'total-bordered-bottom'
              : 'total-bordered-top total-bordered-bottom'
          }
        >
          <IonCol className="paddLR">
            <SmallText color="primary" className="uppercase paddLR">
              <strong>{__('Total')}</strong>
            </SmallText>
          </IonCol>
          <IonCol className="righted paddLR">{total}</IonCol>
        </IonRow>
        {/* <IonRow>
					<IonCol>
						<SmallText color="gray" className="uppercase">
							{ type === 'order' ?
								<>
									{ __('Subtotal') }
									<IonBadge className="small" slot="end">i</IonBadge>
								</> : __('Total')
							}
						</SmallText>
					</IonCol>
					<IonCol className="righted">{ basketInstance._getTotal() }</IonCol>
				</IonRow> */}
      </IonGrid>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    profile: store.profile.profile,
    isRemoveVoucherModalOpen: store.profile.isRemoveVoucherModalOpen,
  };
};

export default connect(mapStateToProps)(withRouter(withTranslation(OrderContent)));
