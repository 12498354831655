import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from '../../lib/translate';
import Layout from '../../components/layout';
import Loading from '../../components/spinner';
import Mobiscroll from '../../components/mobiscroll';
import Basket from '../../lib/basket';
import { Title, FieldError, SmallText, Spacer, Sectiontitle } from '../../components/common';
import {
  IonButton,
  IonItem,
  IonInput,
  IonList,
  IonRadioGroup,
  IonLabel,
  IonRadio,
} from '@ionic/react';
import { isDefined, checkForDeliveryOption } from '../../lib/utils';
import moment from '../../lib/moment';
import {
  setCommonModal,
  getRestaurants,
  getIkentooMenu,
  getIkentooMenusForLocation,
  setDeliveryOption,
  passedTheOrder,
} from '../../store/actions';
import { SHOW_TOAST } from '../../store/constants';
import NoData from '../../components/noData';
import { getMenusForSelectedTime } from '../clickAndCollect';
import './index.css';

const { SelectOption } = Mobiscroll;
const restaurantSelectPlaceholder = 'Select location';

class OrderToTable extends Component {
  state = {
    selectedRestaurant: null,
    error: '',
    isLocationAllowed: false,
    tableNumber: null,
  };

  componentDidMount() {
    Basket.setOrderType('table');
    const deliveryOption = checkForDeliveryOption(
      this.props.deliveryOption ? this.props.deliveryOption : Basket.getDeliveryOption(),
      '/order-to-table',
    );
    if (deliveryOption) {
      this.props.dispatch(getRestaurants());
      this.props.dispatch(setDeliveryOption(deliveryOption));
    }
    if (Basket.getMenu()) {
      this.setState({ selectedIkentooMenu: Basket.getMenu() });
    }
    this.position();
  }

  componentDidUpdate(prevProps) {
    checkForDeliveryOption(this.props.deliveryOption, '/order-to-table');
    if (this.props.ikentooMenusForLocation.length !== prevProps.ikentooMenusForLocation.length) {
      if (this.props.ikentooMenusForLocation[0]) {
        this.setState({ selectedIkentooMenu: this.props.ikentooMenusForLocation[0].ikentooMenuId });
      }
    }
  }

  selectRestaurant = (event, data) => {
    const { restaurants, profile } = this.props;
    const selectedRestaurantId = data.getVal();
    this.setState(
      { selectedRestaurant: selectedRestaurantId, pickTime: moment(Date.now()).format('HH:mm') },
      () => {
        Basket.reset(profile.cardToken);
        Basket.setRestaurant(
          restaurants.find((restaurant) => restaurant.id === selectedRestaurantId),
        );
        Basket.setCollectionTime(null);
        Basket.setOrderType('table');
      },
    );
  };

  formatDataForSelect = (stores) => {
    const { __ } = this.props;
    let arrForSelect = [];
    stores.forEach((store) => {
      if (isDefined(store.can_table_order) && isDefined(store.is_published)) {
        if (store.can_table_order && store.is_published) {
          arrForSelect.push({ text: store.name, value: store.id });
        }
      }
    });

    if (!this.state.isLocationAllowed) {
      arrForSelect.sort(function (a, b) {
        return a.text < b.text ? -1 : a.text > b.text ? 1 : 0;
      });
    }
    return [{ text: __(restaurantSelectPlaceholder), value: null }, ...arrForSelect];
  };

  chooseMenusForLocation = () => {
    const { __, restaurants, dispatch } = this.props;
    const { selectedRestaurant, tableNumber, pickTime } = this.state;
    if (selectedRestaurant && tableNumber && pickTime) {
      const choosenRestaurant = restaurants.find(
        (restaurant) => restaurant.id === selectedRestaurant,
      );
      const businessLocationId = choosenRestaurant.business_location_id;
      if (
        !isDefined(choosenRestaurant.order_to_table_menu_id) ||
        choosenRestaurant.order_to_table_menu_id === ''
      ) {
        dispatch({
          type: SHOW_TOAST,
          message: __('This service is currently unavailable!'),
          toastType: 'warning',
        });
        return;
      }
      const menuId = choosenRestaurant.order_to_table_menu_id;
      let cutoffTimeRes = moment().unix();
      Basket.setCutoffTime(cutoffTimeRes);
      Basket.setTableNumber(tableNumber);
      Basket.setMenu(menuId);
      dispatch(
        getIkentooMenusForLocation(businessLocationId, {
          pickTime,
          json_time_selector: choosenRestaurant ? choosenRestaurant.table_json_time_selector : [],
        }),
      );
      dispatch(passedTheOrder(true));
      Basket.setPassedOrder(true);
    } else if (!selectedRestaurant) {
      this.setState({ error: __('Please select location') });
    } else {
      this.setState({ error: __('Please enter table number') });
    }
  };

  position = async () => {
    await navigator.geolocation.getCurrentPosition(
      (position) =>
        this.setState({
          isLocationAllowed: true,
        }),
      (err) => console.log(err),
    );
  };

  changeIkentooMenus = (event) =>
    this.setState({ selectedIkentooMenu: event.detail.value, error: '' }, () => {
      Basket.setMenu(event.detail.value);
    });

  continueOnMenu = () => {
    const { restaurants, dispatch } = this.props;
    const { selectedIkentooMenu, selectedRestaurant } = this.state;
    if (selectedRestaurant && selectedIkentooMenu) {
      const choosenRestaurant = restaurants.find(
        (restaurant) => restaurant.id === selectedRestaurant,
      );
      const businessLocationId = choosenRestaurant.business_location_id;
      dispatch(getIkentooMenu(selectedIkentooMenu, businessLocationId));
      dispatch(passedTheOrder(true));
      Basket.setPassedOrder(true);
    } else {
      this.setState({ error: 'Please select location menu' });
    }
  };

  render() {
    const {
      __,
      deliveryOption,
      restaurants,
      isChooseMenuModalOpen,
      ikentooMenusForLocation,
    } = this.props;
    const { error, selectedIkentooMenu, pickTime, selectedRestaurant } = this.state;
    const stores = restaurants || [];
    const animationMenuClass = isChooseMenuModalOpen ? 'show-up' : '';
    const store = restaurants.find((restaurant) => restaurant.id === selectedRestaurant) || null;
    const menus = getMenusForSelectedTime(
      ikentooMenusForLocation,
      pickTime,
      store ? store.table_json_time_selector : [],
    );
    return (
      <Loading transparent>
        <Layout
          headerTitle={__(deliveryOption ? deliveryOption.label : '')}
          noPadding
          className=" click-collect-page"
          color="transparent"
        >
          <div className="click-collect-layout  table-order-layout">
            <div className="click-collect-wrapper">
              <Title className="okx-font-secondary">
                {deliveryOption ? deliveryOption.label : ''}
              </Title>
              <SmallText>
                {__(
                  'Enter your table number and your order will be brought to you as soon as possible. This service is available for in-store use only.',
                )}
              </SmallText>
              <Spacer size={4} />
              <label className="select-picker-label okx-font-secondary" htmlFor="demo-non-form">
                {__('Location')}
              </label>
              <SelectOption
                display="center"
                onSet={(e, a) => this.selectRestaurant(e, a)}
                data={this.formatDataForSelect(stores)}
                label="Location"
                value={this.state.selectedRestaurant}
                inputStyle="box"
                placeholder={__(restaurantSelectPlaceholder)}
                setText={__('OK')}
                cancelText={__('Cancel')}
              />
              <br />
              <label className="time-picker-label okx-font-secondary" htmlFor="demo-non-form">
                {__('Table Number')}
              </label>
              {/* <SelectOption
								display="center"
								onSet={ (e, inst) => this.setPickTime(e, inst, minDT) }
								data={ timePickerOptions }
								label="Location"
								value={ pickTime }
								inputStyle="box"
								placeholder={ __('Select Collection Time') }
								setText={ __('OK') }
								cancelText = { __('Cancel') }
								disabled={this.state.selectedRestaurant === null ? true : false}
								onInit={ () => {
									if (timePickerOptions.length > 0) {
										const firstAvailableTime = timePickerOptions.find(i => i.value !== null)
										if (!pickTime && firstAvailableTime && pickTime !== firstAvailableTime.value) {
											this.changeTime(firstAvailableTime.value, minDT)
										}
									}
								}}
							/> */}
							<IonInput className="table-number-input" onIonChange={(e) => this.setState({ tableNumber: e.target.value })} value={this.state.tableNumber} type="text" inputmode="tel" placeholder="Enter table number" />
							{ error ? <IonItem><div tabIndex="-1"></div><FieldError className="field-error" value={ __(error) }/></IonItem> : null}
						</div>
						<div className="flex-min">
							<IonButton disabled={ this.state.tableNumber ? false : true } expand="block" color="secondary" className="uppercase okx-font-secondary round-button" onClick={ () => this.chooseMenusForLocation()}>{ __('Continue')}</IonButton>
						</div>
					</div>
					<div
						className="click-collect-pickers-backdrop"
						style={{ display: isChooseMenuModalOpen ? '' : 'none' }}
						onClick={() => this.props.dispatch(setCommonModal('isChooseMenuModalOpen', false))}>
					</div>
					<div className={ `click-collect-dialog ${animationMenuClass}` }>
						{/* <Loading transparent> {null} </Loading> */}
						<div className="click-collect-dialog-layout sc-ion-modal-md">
							<div className="click-collect-dialog-header">
								<h3>{__('Choose menu')}</h3>
							</div>
							<div
								className="click-collect-dialog-closer"
								style={{ position: 'absolute', right: 0, top: 0 }}
								onClick={() => this.props.dispatch(setCommonModal('isChooseMenuModalOpen', false))}
							>
								<ion-icon name="close" role="img" className="md hydrated" aria-label="close"></ion-icon>
							</div>
							<div className="click-collect-dialog-content">
								<IonList lines="none">
									<IonRadioGroup onIonChange={ this.changeIkentooMenus } value={ selectedIkentooMenu }>
										{
											menus.length === 0 ? <NoData /> : menus.map(menu => {
												const { ikentooMenuId, menuName } = menu
												return (
													<IonItem key={ ikentooMenuId } lines='full'>
														<div tabIndex="-1"></div>
														<IonLabel className="ion-text-wrap">
															<Sectiontitle>{ menuName }</Sectiontitle>
														</IonLabel>
														<IonRadio
															color="secondary"
															slot="start"
															value={ ikentooMenuId }
														/>
													</IonItem>
												)
											})
										}
									</IonRadioGroup>
								</IonList>
							</div>
							<div className="click-collect-dialog-action">
								{ error ? <IonItem><div tabIndex="-1"></div><FieldError className="field-error" value={ __(error) }/></IonItem> : null}
								<IonButton disabled={pickTime && menus.length > 0 ? false : true } expand="block" color="secondary" className="uppercase okx-font-secondary round-button" onClick={ () => this.continueOnMenu() }>{ __('Continue') }</IonButton>
							</div>
						</div>
					</div>
				</Layout>
			</Loading>
		)
	}
}

const stateToProps = (state) => {
  const { deliveryOption } = state.orders;
  const { restaurants, ikentooMenusForLocation } = state.restaurants;
  const { isChooseMenuModalOpen } = state.common;
  return {
    deliveryOption,
    restaurants: restaurants || [],
    profile: state.profile.profile,
    isChooseMenuModalOpen: isChooseMenuModalOpen,
    ikentooMenusForLocation: ikentooMenusForLocation || [],
  };
};

export default connect(stateToProps)(withTranslation(OrderToTable));
