import React from 'react';
import './index.css';

import visa from '../../assets/images/visa.svg';
import mastercard from '../../assets/images/mastercard.svg';
import americanExpress from '../../assets/images/american-express.svg';

const brands = [
  { id: 0, image: visa, alt: 'visa' },
  { id: 0, image: mastercard, alt: 'mastercard' },
  { id: 0, image: americanExpress, alt: 'american-express' },
];

const CardBrands = () => {
  return (
    <div className="card-add-brands">
      {brands.map((itm) => (
        <img key={itm.id} width="100%" className="card-add-brand" src={itm.image} alt={itm.alt} />
      ))}
    </div>
  );
};

export default CardBrands;
