import React from 'react';
import { connect } from 'react-redux';
import { IonButton, IonIcon } from '@ionic/react';
import Loading from '../../components/spinner';
import Layout from '../../components/layout';
import { withTranslation } from '../../lib/translate';
import { checkmarkCircle } from 'ionicons/icons';
import './index.css';
import { Title, NormalText } from '../../components/common';
import { forwardTo } from '../../lib/utils';
import { Capacitor } from '@capacitor/core';

const isWeb = () => Capacitor.platform === 'web';

const MembershipCompleted = ({ __, isMenuFlow }) => {
  const handleMembership = () => {
    forwardTo('/my-membership');
  };

  const handleCheckout = () => {
    const path = isWeb ? '/order' : '/order-summary';
    forwardTo(path);
  };

  return (
    <Loading transparent>
      <Layout hideSecondToolbar={true} color="transparent"  headerTitle={__('Membership Completed')}>
        <div className="absolute-content flex-row-wrapper">
          <div className="scrollable-y ion-text-center">
            <IonIcon
              expand="block"
              color="success"
              icon={checkmarkCircle}
              className="success-icon"
            />
            <Title>{__('Success')}</Title>
            <NormalText tag="div" className="description">
              {__(
                'Thanks for joining Curry Leaf Cafe Club! You’re all set to start enjoying your 20% discount, as well as all your other membership perks',
              )}
            </NormalText>
          </div>
          <div className="flex-min">
            {isMenuFlow ? (
              <IonButton color='secondary' expand="block" className={'checkout-btn '} onClick={handleMembership}>
                {__('View My Membership')}
              </IonButton>
            ) : (
              <IonButton color='secondary' expand="block" className={'checkout-btn '} onClick={handleCheckout}>
                {__('Back to Checkout')}
              </IonButton>
            )}
          </div>
        </div>
      </Layout>
    </Loading>
  );
};

const mapStateToProps = (store) => ({
  isMenuFlow: store.subscription.flow === 'menu_flow',
});

export default connect(mapStateToProps)(withTranslation(MembershipCompleted));
