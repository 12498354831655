import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Loading from '../../components/spinner';
import Layout from '../../components/layout';
import { Spacer, Title } from '../../components/common';
import { IonButton, IonIcon, IonImg, IonList, IonText } from '@ionic/react';
import { withTranslation } from '../../lib/translate';
import './index.css';
import { getSubscriptions } from '../../store/subscription/actions';
import { setSelectedSubscription } from '../../store/orders/actions';
import { forwardTo } from '../../lib/utils';
import { SubscriptionLine } from '../../components/subscription-item';
import flowerImage from '../../assets/images/membership-flower.svg'
import { addCircleOutline, arrowBack, cloudCircle } from 'ionicons/icons';
const handleMembership = () => forwardTo('/membership-terms');

const ChooseSubscription = ({
  __,
   subscriptions,
  selectedSubscription,
  getSubscriptions,
  setSelectedSubscription,
}) => {
  useEffect(() => {
    getSubscriptions();
  }, []);

  let imageUrl = null;
 
  const items = subscriptions.map((e, idx) => {
    const isChecked = idx === selectedSubscription?.idx;
    if (imageUrl === null) {
      imageUrl = e.image;
    }
    const price = e.monthly_cost_inc_vat / 100;

    return SubscriptionLine(
      __,
      () => setSelectedSubscription({ id: e.id, idx }),
      idx,
      isChecked,
      e.name,
      e.short_description,
      price,
    );
  });

  return (
    <Loading transparent>
      <Layout scrollY={false} headerTitle={__('MembershipChooser')}>
        <div className="subscription-backgorund">
          <img src={flowerImage}></img>
        </div>

        <div className="absolute-content  subscription-wrapper">
          <div className='subscription-header'>
            <div className='subscription-header-top'>
              <div className='logo-wrapper'></div>
              <p>JOIN THE <br /> <b>CURRY LEAF CLUB</b><br /> & <b>GET 15%</b><br />OFF  ALL ORDERS</p>
            </div>
            <div className='subscription-header-spacer'></div>
            <div className='subscription-header-bottom'>
            <IonIcon slot="icon-only" icon={addCircleOutline} />

              <div>
                <p>Special Offers & Exclusive Invites</p>
                <p>£5 Per Month</p>
              </div>
            </div>
          </div>
          <div className='subscription-list'>
            <IonList>
              {items}
        
            </IonList>
          </div>
          <IonButton
            disabled={selectedSubscription === null}
            onClick={handleMembership}
            expand="block"
            className={'checkout-btn '}
          >
            {__('Continue')}
          </IonButton>
        </div>
        {/* <div className="absolute-content flex-row-wrapper">
          <div className="scrollable-y">
            <Title className="ion-text-center">
              <strong>HY5 Membership</strong>
            </Title>
            <Spacer size={2} />
            {imageUrl && imageUrl !== '' ? (
              <IonImg className="membership-image" src={imageUrl} alt="" />
            ) : (
              noImage()
            )}
            <IonList>{items}</IonList>
          </div>
          <div className="flex-min">
            <IonButton
              disabled={selectedSubscription === null}
              onClick={handleMembership}
              expand="block"
              className={'checkout-btn '}
            >
              {__('Continue')}
            </IonButton>
          </div>
        </div> */}
      </Layout>
    </Loading>
  );
};

const mapStateToProps = (store) => ({
  subscriptions: store.subscription.subscriptions,
  selectedSubscription: store.orders.selectedSubscription,
});

const mapDispatchToProps = {
  setSelectedSubscription,
  getSubscriptions,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(ChooseSubscription));
