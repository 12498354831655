import React from 'react';
import { connect } from 'react-redux';
import { IonItem, IonButton, IonInput, IonAlert, IonList } from '@ionic/react';
import Layout from '../../components/layout';
import { FieldError, Title, SmallText, Spacer } from '../../components/common';
import { resetPassword, setModal } from '../../store/actions';
import { withTranslation } from '../../lib/translate';
import { validateForm } from '../../lib/utils';
import './index.css';

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      formErrors: {},
    };
    this.handleInput = this.handleInput.bind(this);
    this.handleResetPassword = this.handleResetPassword.bind(this);
    this.formConfig = {
      email: { type: 'email', required: true },
    };
  }

  handleResetPassword() {
    let formErrors = validateForm(this.formConfig, this.state);
    this.setState({ formErrors });
    if (Object.keys(formErrors).length === 0) {
      const { email } = this.state;
      this.props.dispatch(resetPassword(email));
    }
  }

  handleInput = (key, val) => this.setState({ [key]: val });

  returnToLogin = (history) => history.goBack();

	render() {
		const { __, isResetPasswordModalOpen, history } = this.props
		const { email, formErrors } = this.state
		return (
			<>
				<Layout headerTitle={ __('Reset Password') } hideSecondToolbar={ true } color="transparent">
					<div className="flex-row-wrapper absolute-content reset-password-background">
						<div className="scrollable-y">
							<Title>{ __('Forgot password') }</Title>
							{/* <SmallText color="gray">{ __('Enter your email to receive instructions to reset your password') }</SmallText> */}
							<SmallText color="primary" className="heading">{ __('Enter your email address and we\'ll send you instructions on how to reset your password') }</SmallText>
							<Spacer size={ 2 }/>
							<IonList>
								<IonItem className="single-item">
									<IonInput placeholder={ __('Enter your email address')} onIonChange={ e => this.handleInput('email', e.target.value) } clearInput required={ true } type="email" pattern="email" inputmode="email" value={ email } />
								</IonItem>
								{ formErrors.email ? <FieldError className="field-error" value={ __(formErrors.email) }/> : null}
							</IonList>
						</div>
						<div className="flex-min">
							<IonButton className="round-button" expand="block" color="secondary" onClick={ () => this.handleResetPassword() }>{ __('Reset Password') }</IonButton>
							{/* <IonButton expand="block" fill="fill" className="cancel" onClick={() => this.returnToLogin(history)}>{ __('Cancel') }</IonButton> */}
						</div>
					</div>
				</Layout>
				<IonAlert
					isOpen={ isResetPasswordModalOpen }
					onDidDismiss={ () => this.props.dispatch(setModal('isResetPasswordModalOpen', false)) }
					header={ __('Success') }
					message={ __('Reset processed. Please check your mail') }
					buttons={[
						{
							text: __('Close'),
							role: 'cancel',
							cssClass: 'secondary',
							handler: () => this.props.dispatch(setModal(('isResetPasswordModalOpen', false)))
						}
					]}
				/>
			</>
		)
	}
}

const stateToProps = (state) => {
  const { auth, isResetPasswordModalOpen } = state.profile;
  return {
    auth,
    isResetPasswordModalOpen,
  };
};

export default connect(stateToProps)(withTranslation(ResetPassword));
