import React from 'react';
import {
  IonList,
  IonItem,
  IonRadioGroup,
  IonRadio,
  IonLabel,
  IonButton,
  IonAlert,
} from '@ionic/react';
import Loading from '../../components/spinner';
import Layout from '../../components/layout';
import { withTranslation } from '../../lib/translate';
import { Title, SmallText, Spacer, Sectiontitle, NormalText } from '../../components/common';
import { connect } from 'react-redux';
import Basket from '../../lib/basket';
import { forwardTo, insertIntoArray, goBack /*, isEmptyObject*/ } from '../../lib/utils';
import { Capacitor } from '@capacitor/core';
import { getConfig } from '../../appConfig';
import DrawPayButton from '../../components/drawPayButton';
import CheckoutPay from './CheckoutPay';
import './index.css';

const { getMobile, setMobile } = Basket;

const isWeb = () => Capacitor.platform === 'web';

class Checkout extends React.Component {
  constructor(props) {
    super(props);
    if (!getMobile() && this.props.profile && this.props.profile.mobile) {
      setMobile(this.props.profile.mobile);
    }
    this.state = {
      selectedCard: null,
      selectedJudopayCard: null,
      collectedModal: false,
    };
  }

  componentDidMount() {
    const { profile } = this.props;
    const { google } = window;
    if (getConfig().payment === 'judopay') {
      let judoPayCardsLen = profile.judo_pay_cards.length - 1;
      this.changeSelectedPaymentCard(judoPayCardsLen);
    }
    if (profile.cardToken) {
      this.changeSelectedPaymentCard(profile.cardToken);
    }
    window.googlePayClient = new google.payments.api.PaymentsClient({ environment: 'PRODUCTION' });
  }

  componentDidUpdate(prevProps) {
    const { profile } = this.props;
    let judoPayCardsLen = profile.judo_pay_cards.length - 1;
    if (prevProps.profile.cardToken !== profile.cardToken) {
      this.changeSelectedPaymentCard(profile.cardToken);
    }
    if (
      getConfig().payment === 'judopay' &&
      prevProps.profile.judo_pay_cards.length !== profile.judo_pay_cards.length
    ) {
      this.changeSelectedPaymentCard(judoPayCardsLen);
    }
  }

  changeSelectedPaymentCard = (cardId) =>
    this.setState({ selectedCard: cardId }, () => {
      Basket.changeSelectedCard(cardId);
      // this.props.dispatch(updateProfile({ cardToken: cardId }, true)) // reason: this line has commented, because we're updating user profile on the BO
    });

  backHandler = () => {
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.skipContactDetails
    ) {
      forwardTo('/order', { skipBackToThePreviousPage: true });
    } else if (this.props.location.pathname === '/checkout') {
      forwardTo('/order', { skipBackToThePreviousPage: true });
    } else {
      goBack();
    }
  };

  showCollectedModal(modalOpen) {
    this.setState({ collectedModal: modalOpen });
  }

  render() {
    const { __, cards, clientSecret, dispatch, location, profile } = this.props;
    const { collectedModal } = this.state;
    return (
      <Loading transparent>
        <Layout color="white" headerTitle={__('Checkout')} backHandler={this.backHandler}>
          <div className="flex-row-wrapper absolute-content">
            <Title>{__('Checkout')}</Title>
            {/* <SmallText color="primary">{ __('Select Payment Card') }</SmallText> */}
            <SmallText color="primary" className="heading thiner-text">
              {__('Confirm the payment method to use to complete')}
            </SmallText>
            <Spacer size={1} />
            <div className="scrollable-y">
              <IonList lines="full">
                {getConfig().payment === 'judopay' ? (
                  <IonRadioGroup
                    onIonChange={(e) => this.changeSelectedPaymentCard(e.detail.value)}
                    value={this.state.selectedCard}
                  >
                    {cards.map((card, id) => {
                      const { cardLastFour, cardExpiry, cardType, name } = card;
                      let cardEx = insertIntoArray(cardExpiry, 2, '/');
                      return (
                        <IonItem key={id}>
                          <div tabIndex="-1"></div>
                          <IonLabel className="ion-text-wrap">
                            <NormalText color="primary" className="single-item bold">
                              {name || ''}
                            </NormalText>
                            <Sectiontitle className="no-margin">
                              •••• •••• •••• {cardLastFour}
                            </Sectiontitle>
                            <SmallText color="primary">
                              {__(cardType)} - {cardEx}
                            </SmallText>
                          </IonLabel>
                          <IonRadio
                            slot="start"
                            color="secondary"
                            value={id + ''}
                            onIonSelect={() => {
                              this.setState({ selectedCard: id }, () => {
                                Basket.changeSelectedCard(id);
                              });
                            }}
                          />
                        </IonItem>
                      );
                    })}
                  </IonRadioGroup>
                ) : (
                  <IonRadioGroup
                    onIonChange={(e) => this.changeSelectedPaymentCard(e.detail.value)}
                    value={this.state.selectedCard}
                  >
                    {cards.map((card) => {
                      const { id, last4, brand, exp_month, exp_year, name } = card;
                      return (
                        <IonItem key={id}>
                          <div tabIndex="-1"></div>
                          <IonLabel className="ion-text-wrap">
                            <NormalText color="primary" className="single-item bold">
                              {name}
                            </NormalText>
                            <Sectiontitle className="no-margin">
                              •••• •••• •••• {last4}
                            </Sectiontitle>
                            <SmallText color="primary">
                              {__(brand)} - {exp_month}/{exp_year}
                            </SmallText>
                          </IonLabel>
                          <IonRadio
                            slot="start"
                            color="secondary"
                            value={id}
                            onIonSelect={() => {
                              this.setState({ selectedCard: id }, () => {
                                Basket.changeSelectedCard(id);
                              });
                            }}
                          />
                        </IonItem>
                      );
                    })}
                  </IonRadioGroup>
                )}
              </IonList>
              <IonButton
                fill="clear"
                color="secondary"
                className="link underlined"
                onClick={() => forwardTo('/card-add', { addCardFromCheckout: true })}
              >
                {__('Add New Payment Card')}
              </IonButton>
            </div>
            <div className="flex-min">
              {isWeb() ? (
                <CheckoutPay
                  clientSecret={clientSecret}
                  dispatch={dispatch}
                  __={__}
                  location={location}
                  profile={profile}
                />
              ) : (
                <DrawPayButton __={__} googlePayClient={window.googlePayClient} />
              )}
              {getConfig().flags.payOnCollection && Basket.getOrderType() === 'charter-delivery' ? (
                <IonButton
                  onClick={() => this.showCollectedModal(true)}
                  expand="block"
                  color="secondary"
                >
                  {__('Pay') + ' ' + Basket._getTotal() + ' ' + __('On Collection')}
                </IonButton>
              ) : null}
              <IonButton
                color="secondary"
                disabled={cards.length === 0}
                onClick={() => Basket.createOrder()}
                expand="block"
              >
                {__('Pay')} {Basket._getTotal()}
              </IonButton>
            </div>
          </div>
        </Layout>
        <IonAlert
          isOpen={collectedModal}
          onDidDismiss={() => this.showCollectedModal(false)}
          header={__('Pay on Collection')}
          message={__(
            'You agree to pay the amount shown before collecting your order. Earned loyalty will be updated after payment',
          )}
          buttons={[
            {
              text: __('Cancel'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => this.showCollectedModal(false),
            },
            {
              text: __('Confirm'),
              handler: () => {
                Basket.createOrder('collectedPay');
                this.showCollectedModal(false);
              },
            },
          ]}
        />
      </Loading>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    cards: store.orders.cards || [],
    clientSecret: store.orders.clientSecret,
    profile: store.profile.profile || {},
  };
};

export default connect(mapStateToProps)(withTranslation(Checkout));
