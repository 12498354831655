import React from 'react';
import { IonButton, IonInput, IonToggle, IonItem, IonLabel, IonList, IonAlert } from '@ionic/react';
import { connect } from 'react-redux';
// import { getConfig } from '../../appConfig'
import Layout from '../../components/layout';
import PasswordInput from '../../components/passwordInput';
import { forwardTo, getDefaultRoute } from '../../lib/utils';
import { registerRequest, setModal, setSysLocale, setRegisterForm } from '../../store/actions';
import { withTranslation } from '../../lib/translate';
import { FieldError, Title, SmallText, Spacer } from '../../components/common';
import { validateForm } from '../../lib/utils';
import Loading from '../../components/spinner';
import { Plugins } from '@capacitor/core';
import './index.css';

const { Device } = Plugins;

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formErrors: {},
    };
    this.handleInput = this.handleInput.bind(this);
    this.handleRegister = this.handleRegister.bind(this);
    this.navToTerms = this.navToTerms.bind(this);
    this.formConfig = {
      email: { type: 'email', required: true },
      password: { type: 'password', required: true },
      accepted_terms_and_conditions: { type: 'toggle', toggle: true },
    };
  }

  handleInput(key, val) {
    const { dispatch } = this.props;
    dispatch(setRegisterForm(key, val));
    if (key === 'accepted_terms_and_conditions') {
      let formErrors = { ...this.state.formErrors };
      formErrors.accepted_terms_and_conditions = undefined;
      this.setState({ formErrors });
    }
  }

  handleRegister() {
    const { registerFormData, protectedReferrer } = this.props;
    let formErrors = validateForm(this.formConfig, registerFormData);
    this.setState({ formErrors });
    if (Object.keys(formErrors).length === 0) {
      this.props.dispatch(registerRequest({ referrer: protectedReferrer }));
    }
  }

  componentDidMount() {
    const { loggedIn } = this.props.auth;
    Device.getLanguageCode().then((res) => {
      const sysLocale = res.value.substr(0, 2);
      if (sysLocale) {
        this.props.dispatch(setSysLocale(sysLocale));
      }
    });
    if (loggedIn) {
      const defaultRoute = getDefaultRoute();
      forwardTo(defaultRoute.path);
    }
  }

  returnToLogin = (history) => history.goBack();

  navToTerms = () => forwardTo('/terms');

  render() {
    const { __, isRegisterModalOpen, registerFormData } = this.props;
    const email = registerFormData.email;
    const password = registerFormData.password;
    const accepted_terms_and_conditions = registerFormData.accepted_terms_and_conditions;
    const is_subscribed = registerFormData.is_subscribed;

		return (
			<Loading transparent>
				<Layout hideSecondToolbar={true} hideBack={true}  headerTitle={ __('Register') } color="transparent" contentClassName="register-background">
					<Title className="uppercase">{ __('Create an account') }</Title>
					<SmallText className="heading">{ __('To register your account, please enter your email address and a password') }</SmallText>
					<Spacer size={3} />
					<IonList>
						<IonItem>
							<IonInput placeholder={ __('Enter your email address') } onIonChange={ e => this.handleInput('email', e.target.value) } required={ true } type="email" pattern="email" inputmode="email" value={ email }></IonInput>
						</IonItem>
						<FieldError className="field-error" value={ __(this.state.formErrors.email) } />
						<IonItem>
							<PasswordInput placeholder={ __('Enter your password') } onIonChange={ e => this.handleInput('password', e.target.value) } value={ password }/>
						</IonItem>
						<FieldError className="field-error" value={ __(this.state.formErrors.password) } />
					</IonList>
					<Spacer/>
					<div className="box-holder box-holder--register">
						{/* <SmallText tag="p"><span className="link link-primary" onClick={ this.navToTerms }>{ __('View our terms and conditions of use') }</span></SmallText> */}
						<IonList>
							<IonItem>
								<div tabIndex="-1"></div>
								<div className="toggle">
									<IonLabel>
										<h2>{ __('Accept T&Cs') }</h2>
										<IonLabel className="ion-text-wrap thiner-text">
											<SmallText color="primary">{ __('By signing up you agree to') + ' '}</SmallText>
											<SmallText color="primary"><strong onClick={() => forwardTo('/terms')}>{ __('our terms and conditions') }</strong> { __('and') } <strong onClick={() => forwardTo('/privacy')}>{ __('privacy policy') }</strong></SmallText>
											{/* <SmallText color="primary">{ __('of service') }</SmallText> */}
										</IonLabel>
									</IonLabel>
									<FieldError className="field-error" value={ __(this.state.formErrors.accepted_terms_and_conditions) } />
								</div>
								<IonToggle color="secondary" slot="end" checked={ accepted_terms_and_conditions } onIonChange={ e => this.handleInput('accepted_terms_and_conditions', e.detail.checked) }/>
							</IonItem>
							<IonItem lines="none">
								<div tabIndex="-1"></div>
								<IonLabel>
									<h2>{ __('Email Opt in') }</h2>
									<IonLabel className="ion-text-wrap">
										{/* <SmallText color="primary">{ __('I would like to receive') + ' ' + getConfig().general.clientName + ' ' + __('updates and offers via email') }</SmallText> */}
										<SmallText color="primary" className="bold">{ __('I\'d like to receive email updates that contain news, offers and promotions') }</SmallText>
									</IonLabel>
								</IonLabel>
								<IonToggle color="secondary" slot="end" checked={ is_subscribed } onIonChange={ e => this.handleInput('is_subscribed', e.detail.checked) } />
							</IonItem>
						</IonList>
					</div>
					<Spacer size={2} />
					<div className="top-medium">
						<IonButton className="round-button" expand="block" color="secondary" onClick={() => this.handleRegister()}>{ __('Register') }</IonButton>
						{/* <IonButton expand="block" fill="fill" className="cancel" onClick={() => this.returnToLogin(history) }>{ __('Cancel') }</IonButton> */}
					</div>
				</Layout>
				<IonAlert
					isOpen={ isRegisterModalOpen }
					onDidDismiss={ () => this.props.dispatch(setModal('isRegisterModalOpen', false)) }
					header={ __('Success') }
					message={ __('Register processed. Please check your mail') }
					buttons={[
						{
							text: __('Close'),
							role: 'cancel',
							cssClass: 'secondary',
							handler: () => this.props.dispatch(setModal(('isRegisterModalOpen', false)))
						}
					]}
				/>
			</Loading>
		)
	}
}

const stateToProps = (state) => {
  const { auth, isRegisterModalOpen, registerFormData, protectedReferrer } = state.profile;
  return {
    auth,
    isRegisterModalOpen,
    registerFormData,
    protectedReferrer
  };
};

export default connect(stateToProps)(withTranslation(Register));
