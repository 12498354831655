import { call, put, select, takeEvery } from 'redux-saga/effects';
import api from '../../lib/api';
import { GET_PROFILE, SET_PROFILE_PROP } from '../profile/constants';
import {
  CANCEL_USER_SUBSCRIPTION,
  FETCH_SUBSCRIPTIONS,
  FETCH_SUBSCRIPTIONS_OK,
  PAY_MEMBERSHIP,
  REACTIVATE_USER_SUBSCRIPTION,
  PAY_MEMBERSHIP_OK,
  PAY_MEMBERSHIP_FAILED,
  CREATE_SUBSCRIPTION,
} from './constants';
import { forwardTo } from '../../lib/utils';

const getSubscriptionsSaga = function* () {
  //  const subscriptions = yield call(api.getSubscriptions);
  const subscriptions = {"data":[{"id":1,"code":"TESTCODE","name":"Curry Leaf Membership","unlock_code":"unlockcode","lock_text":"Lock Text","short_description":"First month free","description":"","terms":"","monthly_cost_ex_vat":416,"monthly_cost_inc_vat":499,"monthly_credits":0,"discount_rate":20,"image":null,"credit_products":[{"sku":"18009","include_sub_items":true},{"sku":"18005","include_sub_items":false}],"discount_products":[],"allowed_locations":[],"number_of_free_periods":1,"max_discount_per_transaction":400,"plu":"101003","stripe_product_id":"prod_JNvPOpHFxu5Hk0","stripe_price_id":"price_1Il9YpKDc6Keqhes1sAVRvNX"}],"status":200,"statusText":"","headers":{"content-language":"en","content-type":"application/json"},"config":{"url":"https://curry-leaf-api.5loyalty.com/subscription/packages","method":"get","headers":{"Accept":"application/json, text/plain, */*","Authorization":"JWT eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjo0LCJ1c2VybmFtZSI6Im1hcnRpbmFANWxveWFsdHkuY29tIiwiZXhwIjoxNjUxMTUxMTE1LCJlbWFpbCI6Im1hcnRpbmFANWxveWFsdHkuY29tIiwib3JpZ19pYXQiOjE2MTk2MTUxMTV9.99YpQNg3Wl7ADHhxz76x4SL5natvpIg76X3I9dcveOU"},"transformRequest":[null],"transformResponse":[null],"timeout":50000,"xsrfCookieName":"XSRF-TOKEN","xsrfHeaderName":"X-XSRF-TOKEN","maxContentLength":-1,"maxBodyLength":-1},"request":{}}
  yield put({ type: FETCH_SUBSCRIPTIONS_OK, key: 'subscriptions', value: subscriptions });
};

const createSubscriptionSaga = function* (action) {
  const data = yield call(api.createSubscription, { data: action.value, location: '009' });
  yield put({ type: PAY_MEMBERSHIP_OK, data });
  yield put({ type: GET_PROFILE });
  yield call(forwardTo, '/membership-completed', { completedOrder: true });
};

const cancelSubscriptionSaga = function* () {
  const { profile } = yield select();
  profile.profile.active_subscription = {
    ...profile.profile.active_subscription,
    cancellation_pending: true,
  };
  const result = yield call(api.cancelUserSubscription);
  if (result.status === 201) {
    yield put({ type: SET_PROFILE_PROP, key: 'profile', value: profile.profile });
  }
};

const reactivateSubscriptionSaga = function* () {
  const { profile } = yield select();
  profile.profile.active_subscription = {
    ...profile.profile.active_subscription,
    cancellation_pending: false,
  };
  const result = yield call(api.cancelUserSubscription, { cancel: false });
  if (result.status === 201) {
    yield put({ type: SET_PROFILE_PROP, key: 'profile', value: profile.profile });
  }
};

const payMembershipSaga = function* (action) {
  try {
    const data = yield call(api.payMembership, action.value);
    yield put({ type: PAY_MEMBERSHIP_OK, data });
    yield put({ type: GET_PROFILE });
    yield call(forwardTo, '/membership-completed', { completedOrder: true });
  } catch (error) {
    yield put({ type: PAY_MEMBERSHIP_FAILED, error });
  }
};

export default function* subscriptionSagas() {
  yield takeEvery(FETCH_SUBSCRIPTIONS, getSubscriptionsSaga);
  yield takeEvery(CANCEL_USER_SUBSCRIPTION, cancelSubscriptionSaga);
  yield takeEvery(REACTIVATE_USER_SUBSCRIPTION, reactivateSubscriptionSaga);
  yield takeEvery(PAY_MEMBERSHIP, payMembershipSaga);
  yield takeEvery(CREATE_SUBSCRIPTION, createSubscriptionSaga);
}
