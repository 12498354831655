import React from 'react';
import { IonGrid, IonRow, IonCol, IonRefresher, IonRefresherContent } from '@ionic/react';
import { withTranslation } from '../../lib/translate';
import { withRouter } from 'react-router';
// import { arrowForward } from 'ionicons/icons'
import { SmallText } from '../../components/common';
import NoData from '../../components/noData';
import Basket from '../../lib/basket';
import earnedArrow from '../../assets/images/earned-arrow.svg';
import redeemedArrow from '../../assets/images/redeemed-arrow.svg';
import { getConfig } from '../../appConfig';
import { isDefined } from '../../lib/utils';

const doRefresh = (event, getTransactionHistory) => {
  setTimeout(() => {
    event.detail.complete();
    getTransactionHistory();
  }, 1000);
};

const HistoryTab = withRouter(
  ({ __, transactionHistory, getTransactionHistory, history, ...rest }) => {
    const type = rest.type || 'order';
    const delivery = isDefined(getConfig().delivery) ? getConfig().delivery : [];

    const handleRowClick = (item) => {
      if (type === 'order') {
        history.push('./history-details', { order: item });
      } else {
        // history.push('./history-details')
      }
    };

    return (
      <>
        <IonRefresher slot="fixed" onIonRefresh={(e) => doRefresh(e, getTransactionHistory)}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <div className="history-content">
          {(transactionHistory || []).length === 0 ? (
            <NoData />
          ) : (
            <IonGrid>
              {transactionHistory.map((i, index) => {
                const { stamp_power, transaction_date, location_name, label, item } = i;
                // const splitedLabel = label.split('#').splice(1).join('')
                const labelLocationName = label.split('#').splice(0).join('');
                let orderLabel = Basket.getOrderType(i.item);
                // let option = (delivery || []).find(d => d.id === orderLabel)
                let option = (delivery || []).find((d) => d.id.includes(orderLabel));
                let status = item && item.status ? item.status : '';
                let orderId = i && i.item ? ' #' + i.item.id : '';
                return status === 'CREATED' ||
                  status === 'created' ||
                  status === 'new' ||
                  status === 'NEW' ? null : (
                  <IonRow onClick={() => handleRowClick(item)} key={index} className="history-item">
                    <IonCol>
                      <SmallText
                        color="primary"
                        className={`${type === 'order' && 'order-location-name'} thiner-text`}
                      >
                        {type === 'order' ? labelLocationName : location_name}
                      </SmallText>
                      <h2>
                        {type === 'order' ? (option ? option.label + orderId : '') : __(label)}
                      </h2>
                      <SmallText color="primary">
                        <span className="capitalize">
                          {type === 'order' ? option && __(`${option.id}:`) : ''}
                        </span>{' '}
                        {Basket.getDate(transaction_date).format('DD MMMM YYYY [at] h:mm a')}
                      </SmallText>
                    </IonCol>
                    {type === 'order' ? (
                      <IonCol style={{ flex: '0 1 0', alignSelf: 'center' }}>
                        <div className="order-button-wrapper">
                          <div className="order-button bordered-primary">
                            {__(item && item.status ? item.status : '')}
                            {/* <IonIcon color="primary" slot="end" icon={ arrowForward }/> */}
                          </div>
                        </div>
                      </IonCol>
                    ) : (
                      <IonCol style={{ alignSelf: 'center' }}>
                        <p className={stamp_power < 0 ? 'redeem' : ''}>
                          <img
                            src={stamp_power < 0 ? redeemedArrow : earnedArrow}
                            alt="point-arrows"
                          />
                          {stamp_power < 0 ? '' : '+'}
                          {stamp_power} {__('points')}
                        </p>
                      </IonCol>
                    )}
                  </IonRow>
                );
              })}
            </IonGrid>
          )}
        </div>
      </>
    );
  },
);

export default withTranslation(HistoryTab);
