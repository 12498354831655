/* eslint-disable no-mixed-spaces-and-tabs */
import React from 'react';
import { IonButton, IonItem, IonInput } from '@ionic/react';
import { connect } from 'react-redux';
import Loading from '../../components/spinner';
// import { close } from 'ionicons/icons'
import { withTranslation } from '../../lib/translate';
import { deepIsDefined, goBack, validateForm } from '../../lib/utils';
import { sendVoucherCode, checkDiscount, showToast } from '../../store/actions';
import Layout from '../../components/layout';
import { Title, Spacer, FieldError /*, NormalText, SmallText*/ } from '../../components/common';

import Basket from '../../lib/basket';
import Modal from '../../components/modal';
import { getConfig } from '../../appConfig';
import './index.css';

class ApplyVouchers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      discounts: null,
      selectedVoucher: null,
      showModal: null,
      voucherCode: '',
      disabledAppliedButton: false,
      formErrors: {
        voucherCode: '',
      },
    };
    this.formConfig = {
      voucherCode: { type: 'text', required: true },
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(checkDiscount(Basket.parseBasketForCheckVouchers()));
  }

  setVoucher = (voucher) => {
    this.setState({ selectedVoucher: voucher }, () => {
      const { vouchers, applicableVouchers } = this.props;
      const { selectedVoucher } = this.state;
      const appliedVoucher = vouchers.find((voucher) => voucher.id === selectedVoucher.id);
      const applicableVoucher = applicableVouchers.find((appliableVoucher) => {
        return getConfig().vouchersType === 1 || getConfig().vouchersType === 2
          ? applicableVouchers.find(
              (appliableVoucher) => appliableVoucher.id === selectedVoucher.id,
            )
          : appliableVoucher.type && appliableVoucher.type === 1
          ? appliableVoucher.id === selectedVoucher.id
          : appliableVoucher.id === selectedVoucher.reward.id;
      });
      if (Basket.canVoucherBeApplied(appliedVoucher, applicableVoucher)) {
        this.setState({ disabledAppliedButton: true });
      } else {
        this.setState({ disabledAppliedButton: false });
      }
    });
  };

  addVoucher = () => {
    const { selectedVoucher } = this.state;
    const { vouchers, applicableVouchers, dispatch, __ } = this.props;
    let appliedVoucher = [];
    let applicableVoucher = [];
    if (selectedVoucher && selectedVoucher.type) {
      appliedVoucher = vouchers.find(
        (voucher) => voucher.type && voucher.reward.id === selectedVoucher.reward.id,
      );
      applicableVoucher = applicableVouchers.find((appliableVoucher) => {
        return getConfig().vouchersType === 1 || getConfig().vouchersType === 2
          ? applicableVouchers.find(
              (appliableVoucher) => appliableVoucher.id === selectedVoucher.id,
            )
          : appliableVoucher.type && appliableVoucher.type === 1
          ? appliableVoucher.id === selectedVoucher.id
          : appliableVoucher.id === selectedVoucher.reward.id;
      });
    } else if (selectedVoucher) {
      appliedVoucher = vouchers.find((voucher) => voucher.id === selectedVoucher.id);
      applicableVoucher = applicableVouchers.find((appliableVoucher) =>
        appliableVoucher.type && appliableVoucher.type === 1
          ? appliableVoucher.id === selectedVoucher.id
          : appliableVoucher.id === selectedVoucher.reward.id,
      );
    }
    if (Basket.canVoucherBeApplied(appliedVoucher, applicableVoucher)) {
      this.setState({ selectedVoucher: null }, () => {
        Basket.addVoucher(appliedVoucher, applicableVoucher);
        goBack();
      });
    } else {
      dispatch(
        showToast(
          __('Your basket is higher then voucher cost. Please add more items in basket'),
          'warning',
        ),
      );
    }
  };

  showModal = (show) => {
    this.setState({ showModal: show });
    this.clearFields();
  };

  clearFields = () => {
    this.setState({ voucherCode: '' });
  };

  sendCode = () => {
    const { dispatch, __ } = this.props;
    let formErrors = validateForm(this.formConfig, this.state, __);
    this.setState({ formErrors });
    if (Object.keys(formErrors).length === 0) {
      const { voucherCode } = this.state;
      const data = { code: voucherCode.toUpperCase() };
      this.clearFields();
      dispatch(sendVoucherCode(data));
      this.showModal(false);
    }
  };

  filterVouchers = (vouchers, appliedVouchers) => {
    const { applicableVouchers, availableBalance } = this.props;
    const vouchersType = getConfig().vouchersType;
    let filteredVouchers = [];
    if (applicableVouchers && applicableVouchers.length > 0) {
      applicableVouchers.forEach((discount) => {
        let findedVoucher = vouchers.find((voucher) =>
          voucher && voucher.type === 1
            ? voucher.reward.id === discount.reward_sku
            : discount.type === 2
            ? voucher.reward.id === discount.id && availableBalance >= voucher.reward.cost
            : null,
        );
        if (vouchersType === 1) {
          if (findedVoucher) {
            filteredVouchers.push(findedVoucher);
          }
        } else if (vouchersType === 2) {
          if (findedVoucher && availableBalance > findedVoucher.cost) {
            filteredVouchers.push(findedVoucher);
          }
        } else {
          if (findedVoucher) {
            if (!findedVoucher.type && availableBalance >= findedVoucher.reward.cost) {
              filteredVouchers.push(findedVoucher);
            } else if (findedVoucher.type) {
              filteredVouchers.push(findedVoucher);
            }
          }
        }
      });
    }
    if (appliedVouchers && appliedVouchers.length > 0) {
      const ids = appliedVouchers.map((i) => i.id);
      return filteredVouchers.filter((filteredVoucher) => ids.indexOf(filteredVoucher.id) === -1);
    }
    return filteredVouchers;
  };

  render() {
    const { __, vouchers } = this.props;
    const { selectedVoucher, voucherCode, showModal, disabledAppliedButton } = this.state;
    const appliedVouchers = Basket ? Basket.applied_vouchers : [];
    const filteredVouchers = this.filterVouchers(vouchers, appliedVouchers);

    return (
      <>
        <Loading transparent>
          <Layout headerTitle={__('Apply Vouchers')}>
            <div className="flex-row-wrapper absolute-content">
              {getConfig().vouchersType === 2 ? null : (
                <div className="flex-min">
                  <IonButton expand="block" color="secondary" onClick={() => this.showModal(true)}>
                    {__('Voucher code')}
                  </IonButton>
                </div>
              )}
              <Spacer />
              <Title className="centered">{__('Available Vouchers')}</Title>
              <Spacer />
              <div className="scrollable-y">
                {filteredVouchers.length === 0 ? (
                  <p style={{ textAlign: 'center' }}>{__('No data')}</p>
                ) : null}
                {filteredVouchers.length > 0 &&
                  filteredVouchers.map((filteredVoucher, index) => {
                    return (
                      <div
                        key={index}
                        onClick={() => this.setVoucher(filteredVoucher)}
                        className={
                          selectedVoucher && selectedVoucher.id === filteredVoucher.id
                            ? 'apply-voucher'
                            : 'apply-voucher-box'
                        }
                      >
                        <img alt="" src={filteredVoucher.reward.image} width="100%" />
                        <div>
                          <div className="centered">
                            <p>{__(filteredVoucher.reward.name)}</p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <div className="flex-min">
                <IonButton
                  color="secondary"
                  disabled={selectedVoucher ? false : true}
                  onClick={() => {
                    this.addVoucher();
                  }}
                  className={
                    disabledAppliedButton ? 'box-button-color-tertiary' : 'box-button-color'
                  }
                  size="full"
                  shape="round"
                >
                  {__('Apply Vouchers')}
                </IonButton>
                <IonButton expand="full" onClick={goBack} color="secondary">
                  {__('Back')}
                </IonButton>
              </div>
            </div>
          </Layout>
          <Modal
            className="apply-voucher-modal"
            isOpen={!!showModal}
            onDidDismiss={() => this.setState({ showModal: null })}
            title={__('Enter code')}
          >
            <div className="flex-row-wrapper">
              <div className="centered flex-row-wrapper">
                <div className="pad20t centered scrollable-y">
                  <p>{__('Enter a valid voucher code below to receive your reward')}</p>
                  <div className="lefted padded voucher-item">
                    <IonItem style={{ '--min-height': '32px' }}>
                      <IonInput
                        value={voucherCode}
                        onIonChange={(e) => this.setState({ voucherCode: e.target.value })}
                        placeholder={__('Voucher code')}
                      />
                    </IonItem>
                    <FieldError
                      className="field-error"
                      value={__(this.state.formErrors.voucherCode)}
                    />
                  </div>
                </div>
              </div>
              <div className="flex-min">
                <IonButton onClick={() => this.sendCode()} expand="full" color="secondary">
                  {__('Submit')}
                </IonButton>
              </div>
            </div>
          </Modal>
        </Loading>
      </>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    availableBalance: deepIsDefined(store, 'profile.profile.available_balance')
      ? store.profile.profile.available_balance
      : 0,
    basketUpadated: store.orders.basketUpdated,
    vouchers: store.profile.vouchers || null,
    applicableVouchers: store.profile.applicableVouchers || null,
  };
};

export default connect(mapStateToProps)(withTranslation(ApplyVouchers));
