import React from 'react';
import QRCode from 'qrcode-react';
import ValidateButton from '../../components/validateButton';
import { withTranslation } from '../../lib/translate';
import { isDefined, validateProfileData } from '../../lib/utils';
import { getConfig } from '../../appConfig';
import { SmallText } from '../../components/common';

const Scan = ({ __, qr_code, profile }) => {
  const valid = validateProfileData(profile).isValid;
  const { first_name, last_name } = profile;
  const pointsValuePercentage = getConfig().api_config
    ? getConfig().api_config.points_value_percentage
    : 0;
  const point = pointsValuePercentage > 1 ? ' points' : ' point';
  return (
    <>
      <div className="loyalty-content scan scrollable-y">
        <div className="loyalty-title-wrap">
          <h2>{__(`${first_name} ${last_name}`)}</h2>
          <SmallText color="primary" className="heading">
            {__('Collect points every time you scan')}.
          </SmallText>
          <br />
          <SmallText color="primary" className="heading">
            {__('Earn ' + pointsValuePercentage + point + ' for every £1 spent')}.
          </SmallText>
          {/* <SmallText>{ __('For every £1 spent receive 10 points worth £0.10') }</SmallText> */}
        </div>
        {isDefined(qr_code) ? (
          <div className="qr-holder">
            <QRCode value={qr_code} size={200} />
          </div>
        ) : (
          <div className="noQrCode">
            <h5>{__('NO QR CODE')}</h5>
          </div>
        )}
        <ValidateButton />
        {!valid && getConfig().appType.hasEmailValidationEnabled ? (
          <div className="verified-content">
            <SmallText color="grey">
              {__('You can earn, but not redeem points until your account is verified')}
            </SmallText>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default withTranslation(Scan);
